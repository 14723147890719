import { createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup, signOut, updatePassword } from 'firebase/auth';
import { googleAuthProvider, facebookAuthProvider, auth } from '../firebase/firebase';

export const login = (uid, displayName, photoURL) => ({
  type: 'LOGIN',
  uid,
  displayName,
  photoURL
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const startLoginGoogle = () => {
  return () => {
    return signInWithPopup(auth, googleAuthProvider)
      .catch((error) => {
        alert(error.message);
      })
  }
};

export const startLoginFacebook = () => {
  return () => {
    return signInWithPopup(auth, facebookAuthProvider)
      .catch((error) => {
        alert(error.message);
      })
  }
};

export const registerEmailPassword = (username, email, password) => {
  return () => {
    return createUserWithEmailAndPassword(auth, email, password)
      .then((authUser) => {
        authUser.user.updateProfile({
          displayName: username
        })
      })
  }
};

export const emailVerification = () => {
  return () => {
    return sendEmailVerification(auth.currentUser)
  }
};

export const startLoginEmail = (email, password) => {
  return () => {
    return signInWithEmailAndPassword(auth, email, password)
  }
};

export const doPasswordReset = (email) => {
  return () => {
    return sendPasswordResetEmail(auth, email);
  }
};

export const doPasswordUpdate = (password) => {
  return () => {
    return updatePassword(auth.currentUser, password);
  }
};

export const startLogout = () => {
  return () => {
    return signOut(auth)
  }
};