import moment from 'moment';

// GET VISIBLE EXPENSES
export default (expenses, { text, category, sortBy, startDate, endDate }) => {
  return expenses.filter((expense) => {
    const onlyIncome = !expense.isExpense;
    const createdAtMoment = moment(expense.createdAt);
    const startDateMatch = startDate ? startDate.isSameOrBefore(createdAtMoment, 'day') : true;
    const endDateMatch = endDate ? endDate.isSameOrAfter(createdAtMoment, 'day') : true;
    const textMatch = expense.description.toLowerCase().includes(text.toLowerCase());
    const categoryMatch = category === '' || expense.category.toLowerCase() === category.toLowerCase();

    return onlyIncome && startDateMatch && endDateMatch && textMatch && categoryMatch;
  }).sort((a, b) => {
    if (sortBy === 'date') {
      return a.createdAt < b.createdAt ? 1 : -1;
    } else if (sortBy === 'amount') {
      return a.amount < b.amount ? 1 : -1;
    }
  });
}