import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import selectMonthlyExpenses from '../selectors/monthlyExpenses';
import selectMonthlyIncome from '../selectors/monthlyIncome';
import selectExpensesTotal from '../selectors/expenses-total';

const Balance = ({ expensesTotal, isIncome}) => {
  const formattedExpensesTotal = numeral(expensesTotal / 100).format('$0,0.00');

  return (
    <div className="balance-box">
      <h3 className="balance-box__title">Total Balance</h3>
      <h1 className="balance-box__total">{formattedExpensesTotal}</h1>
      <Link to={isIncome ? '/' : '/income'} className={`${'balance-box__link'} ${isIncome ? 'bgExpense' : 'bgIncome'}`}>View {isIncome ? 'Expenses' : 'Income'}</Link>
      <p className="balance-box__text">Display All Time Balance by removing Date Range Filter</p>
    </div>
  )
};

const mapStateToProps = (state) => {
  const visibleExpenses = selectMonthlyExpenses(state.expenses, state.filters);
  const visibleIncome = selectMonthlyIncome(state.expenses, state.filters);

  return {
    expensesTotal: (selectExpensesTotal(visibleIncome) - selectExpensesTotal(visibleExpenses))
  };
};

export default connect(mapStateToProps)(Balance);