import React from 'react';
import List from './List';
import ListFilters from './ListFilters';
import Summary from './Summary';
import Balance from './Balance';

const DashboardPage = () => {
  return (
    <div>
      <div className="dashboard-header">
        <div className="content-container top-summary">
          <Balance/>
          <Summary />
        </div>
      </div>
      <ListFilters />
      <List />
    </div>
  );
};

export default DashboardPage;