import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Category from './Category';
import selectCategories from '../selectors/categories';

const Categories = (props) => (
  <div className="content-container">
    <div className="list-header">
      <div className="show-for-mobile">Categories</div>
      <div className="show-for-desktop">Category</div>
      <div className="show-for-desktop">Color</div>
    </div>
    <div className="list-body">
      {
        props.categories.length === 0 ? (
          <div className="list-item list-item--message">
            <span>No categories</span>
          </div>
        ) : (
          props.categories.map((category) => {
            return <Category key={category.id} {...category} />
          })
        )
      }
    </div>
    <Link to="/add-category" className="add add--category"></Link>
  </div>
);

const mapStateToProps = (state) => {
  return {
    categories: selectCategories(state.categories, state.filters)
  }
};

export default connect(mapStateToProps)(Categories);