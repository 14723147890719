import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import { capitalize } from '../functions/functions';

export default class Form extends Component {

  componentWillReceiveProps(nextProps) {
    if (nextProps.type !== this.state.type) {
      this.setState(() => ({ isExpense: nextProps.type }));
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      description: props.item ? props.item.description : '',
      amount: props.item ? (props.item.amount / 100).toString() : '',
      note: props.item ? props.item.note : '',
      category: props.item ? props.item.category : '',
      createdAt: props.item ? moment(props.item.createdAt) : moment(),
      isExpense: props.item ? props.item.isExpense : props.type,
      calendarFocused: false,
      expensesCategories: props.expensesCategories ? props.expensesCategories : '',
      error: ''
    };
  }

  onDescriptionChange = (e) => {
    const description = e.target.value;
    this.setState(() => ({ description }));
  };

  onAmountChange = (e) => {
    const amount = e.target.value;
    if (!amount || amount.match(/^\d{1,}(\.\d{0,2})?$/)) {
      this.setState(() => ({ amount }));
    }
  };

  onCategoryChange = (e) => {
    const category = e.target.value;
    this.setState(() => ({ category }))
  };

  onNoteChange = (e) => {
    const note = e.target.value;
    this.setState(() => ({ note }));
  };

  onDateChange = (createdAt) => {
    if (createdAt) {
      this.setState(() => ({ createdAt }));
    }
  };

  onFocusChange = ({ focused }) => {
    this.setState(() => ({ calendarFocused: focused }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.description || !this.state.amount || !this.state.category) {
      this.setState(() => ({ error: 'Please provide description, amount and choose a category.' }));
    } else {
      this.setState(() => ({ error: '' }));
      this.props.onSubmit({
        isExpense: this.state.isExpense,
        description: this.state.description,
        amount: parseFloat(this.state.amount, 10) * 100,
        createdAt: this.state.createdAt.valueOf(),
        note: this.state.note,
        category: this.state.category
      })
    }
  };

  render() {
    return (
      <div>
        { this.state.error && <p className="error">{this.state.error}</p> }
        <form className="form" onSubmit={this.onSubmit}>
          <input type="hidden" value={this.state.isExpense} />
          <input
            type="text"
            placeholder="Name"
            autoFocus
            className="text-input"
            value={this.state.description}
            onChange={this.onDescriptionChange}
          />
          <input
            type="text"
            placeholder="Amount"
            className="text-input"
            value={this.state.amount}
            onChange={this.onAmountChange}
          />
          <select
            className="select"
            value={this.state.category}
            onChange={this.onCategoryChange}
          >
            <option value="">Choose category</option>
            {
              this.state.expensesCategories &&
              this.state.expensesCategories.map((category) => {
                if (this.state.isExpense) {
                  if (category.type === 'expenses') {
                    return (<option value={category.name} key={category.id}>{capitalize(category.name)}</option>);
                  }
                } else if (category.type === 'income') {
                  return (<option value={category.name} key={category.id}>{capitalize(category.name)}</option>);
                }
              })
            }
          </select>
          <div className="input-group input-group--small">
            <SingleDatePicker
               date={this.state.createdAt}
               onDateChange={this.onDateChange}
               focused={this.state.calendarFocused}
               onFocusChange={this.onFocusChange}
               numberOfMonths={1}
               isOutsideRange={(day) => false}
            />
            <Link className="form-category" to="/add-category">Add Category</Link>
          </div>
          <textarea
            placeholder="Add a note (optional)"
            className="textarea"
            value={this.state.note}
            onChange={this.onNoteChange}
          >
          </textarea>
          <button className="button button--register">{this.state.isExpense ? 'Save Expense' : 'Save Income'}</button>
        </form>
      </div>
    )
  }
}