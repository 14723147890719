import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter, { history } from './routers/AppRouter';
import configureStore from './store/configureStore';
import { startSetExpenses } from './actions/expenses';
import { startSetCategories, startAddDefaultCategories } from './actions/categories';
import { login, logout } from './actions/auth';
import 'normalize.css/normalize.css';
import './styles/styles.sass';
import 'react-dates/lib/css/_datepicker.css';
import Loading from './components/Loading';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase/firebase';

const store = configureStore();
const jsx = (
  <Provider store={store}>
    <AppRouter />
  </Provider>
);
const rootApp = document.getElementById('app');
let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, rootApp);
    hasRendered = true;
  }
};

ReactDOM.render(<Loading />, rootApp);

onAuthStateChanged(auth, (user) => {
  if ((user && user.providerData[0].providerId === 'facebook.com') || (user && user.emailVerified)) {
    store.dispatch(login(user.uid, user.displayName, user.photoURL));
    store.dispatch(startSetExpenses()).then(
        async () => {
          await store.dispatch(startAddDefaultCategories());
          await store.dispatch(startSetCategories());
          renderApp();
          if (history.location.pathname === '/') {
            history.push('/dashboard');
          }
        }
    );
  } else {
    store.dispatch(logout());
    renderApp();
    history.push('/');
  }
});
