import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { Link } from 'react-router-dom';

export default class CategoryForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: props.item ? props.item.name : '',
      color: props.item ? props.item.color : '#4A90E2',
      type: props.item ? props.item.type : '',
      showPicker: false,
      error: ''
    }
  }

  onNameChange = (e) => {
    const name = e.target.value;
    this.setState(() => ({ name }));
  };

  onTypeChange = (e) => {
    const type = e.target.value;
    this.setState(() => ({ type }))
  };

  onColorOpen = () => {
    this.setState(() => ({ showPicker: !this.showPicker }))
  };

  onColorClose = () => {
    this.setState(() => ({ showPicker: false }))
  };

  onColorChange = (e) => {
    const color = e.hex;
    this.setState(() => ({ color }));
  };

  onSubmit = (e) => {
    e.preventDefault();

    if (!this.state.name || !this.state.type) {
      this.setState(() => ({ error: 'Please provide name and type of category.' }));
    } else {
      this.setState(() => ({ error: '' }));
      this.props.onSubmit({
        name: this.state.name,
        color: this.state.color,
        type: this.state.type
      });
    }
  };

  render() {
    const background = {
      backgroundColor: this.state.color
    };

    return (
      <div>
        { this.state.error && <p className="error">{this.state.error}</p> }
        <form className="form" onSubmit={this.onSubmit}>
          <div className="input-group inline">
            <input
              type="text"
              placeholder="Name"
              autoFocus
              className="text-input"
              value={this.state.name}
              onChange={this.onNameChange}
            />
          </div>
          <div className="input-group inline mb-1">
            <input
              type="radio"
              id="expensesCategory"
              name="type"
              className="radio-input"
              value="expenses"
              onChange={this.onTypeChange}
              defaultChecked={this.state.type === 'expenses'}
            />
            <label htmlFor="expensesCategory">Expenses Category</label>
          </div>
          <div className="input-group inline">
            <input
              type="radio"
              id="incomeCategory"
              name="type"
              className="radio-input"
              value="income"
              onChange={this.onTypeChange}
              defaultChecked={this.state.type === 'income'}
            />
            <label htmlFor="incomeCategory">Income Category</label>
          </div>
          <div className="input-group inline">
            <div id="swatch" onClick={this.onColorOpen}>
              <div id="color" style={background} />
            </div>
            { this.state.showPicker ? <div id="popover">
              <div id="cover" onClick={this.onColorClose}/>
              <SketchPicker color={this.state.color} onChange={this.onColorChange} />
            </div> : null }
            <label htmlFor="swatch">Color</label>
          </div>
          <button className="button">Save Category</button>
        </form>
      </div>
    )
  }
}