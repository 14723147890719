import React from 'react';
import { connect } from 'react-redux';
import ListItem from './ListItem';
import selectExpenses from '../selectors/expenses';

const List = (props) => {

  return (
      <div id="listFade" className="content-container">
        {/*<div className="list-header">*/}
        {/*<div className="show-for-mobile">Expenses</div>*/}
        {/*<div className="show-for-desktop">Expense</div>*/}
        {/*<div className="show-for-desktop">Amount</div>*/}
        {/*</div>*/}
        <div className="list-body">
          {
            props.expenses.length === 0 ? (
                <div className="list-item list-item--message">
                  <span>No expenses</span>
                </div>
            ) : (
                props.expenses.map((expense) => {
                  return <ListItem key={expense.id} {...expense} />
                })
            )
          }
        </div>
      </div>
  );
};

const mapStateToProps = (state) => {
  return {
    expenses: selectExpenses(state.expenses, state.filters),
    showFilters: state.showFilters
  }
};

export default connect(mapStateToProps)(List);