import React from 'react';
import ListIncome from './ListIncome';
import IncomeFilters from './IncomeFilters';
import IncomeSummary from './IncomeSummary';
import Balance from './Balance';

const DashboardPage = () => (
  <div>
    <div className="dashboard-header">
      <div className="content-container top-summary">
        <Balance isIncome={true} />
        <IncomeSummary />
      </div>
    </div>
    <IncomeFilters />
    <ListIncome />
  </div>
);

export default DashboardPage;