import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from '../functions/functions';

const Category = ({ id, name, color, type }) => {
  const showColor = {
    display: 'inline-block',
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    backgroundColor: `${color}`
  };

  return (
    <Link className="list-item" to={`/edit-category/${id}`}>
      <div>
        <h3 className="list-item__title">{capitalize(name)}</h3>
        <span className="list-item__subtitle">{type}</span>
      </div>
      <div style={showColor}> </div>
    </Link>
  )
};

export default Category;