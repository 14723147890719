import React from 'react';

class AccountPage extends React.Component {
  render () {
    return (
      <div>
        Account
      </div>
    )
  }
}

export default AccountPage;