import React from 'react';
import { Router, Route, Switch, Link, NavLink } from 'react-router-dom';
import { createBrowserHistory } from "history";
import DashboardPage from '../components/Dashboard';
import AddPage from '../components/Add';
import EditPage from '../components/Edit';
import NotFoundPage from '../components/NotFound';
import CategoriesDashboardPage from '../components/CategoriesDashboard';
import EditCategory from '../components/EditCategory';
import AddCategory from '../components/AddCategory';
import IncomeDashboard from '../components/IncomeDashboard';
import AccountPage from '../components/Account';
import Statistics from '../components/Statistics';
import StatisticsCategories from '../components/StatisticsCategories';
import StatisticsBoth from '../components/StatisticsBoth';
import StatisticsBalance from '../components/StatisticsBalance';
import StatisticsReports from '../components/StatisticsReports';
import LoginPage from '../components/Login';
import RegisterPage from '../components/Register';
import ForgotPage from '../components/Forgot';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

export const history = createBrowserHistory();

const AppRouter = () => (
  <Router history={history}>
    <div>
      <Switch>
        <PublicRoute path="/" exact={true} component={LoginPage} />
        <PublicRoute path="/register" component={RegisterPage} />
        <PublicRoute path="/forgot" component={ForgotPage} />
        <PrivateRoute path="/account" component={AccountPage} />
        <PrivateRoute path="/dashboard" component={DashboardPage} />
        <PrivateRoute path="/add/:type(expense|income)" component={AddPage} />
        <PrivateRoute path="/edit/:id" component={EditPage} />
        <PrivateRoute path="/categories" component={CategoriesDashboardPage} />
        <PrivateRoute path="/add-category" component={AddCategory} />
        <PrivateRoute path="/edit-category/:id" component={EditCategory} />
        <PrivateRoute path="/income" component={IncomeDashboard} />
        <PrivateRoute path="/statistics" component={Statistics} />
        <PrivateRoute path="/statistics-categories" component={StatisticsCategories} />
        <PrivateRoute path="/statistics-both" component={StatisticsBoth} />
        <PrivateRoute path="/statistics-balance" component={StatisticsBalance} />
        <PrivateRoute path="/statistics-reports" component={StatisticsReports} />
        <Route component={NotFoundPage} />
      </Switch>
    </div>
  </Router>
);

export default AppRouter;