import { database } from '../firebase/firebase';
import { get, onValue, push, ref, remove, set, update } from "firebase/database";

// ADD EXPENSE
export const addExpense = (expense) => ({
    type: 'ADD_EXPENSE',
    expense
});

export const startAddExpense = (expenseData = {}) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    const {
      description = '',
      category = '',
      note = '',
      amount = 0,
      createdAt = 0,
      isExpense = true
    } = expenseData;
    const expense = { description, category, note, amount, createdAt, isExpense };

    const addExpenseRef = ref(database, `users/${uid}/expenses`);
    
    return push(addExpenseRef, expense)
      .then((ref) => {
        dispatch(addExpense({
          id: ref.key,
          ...expense
        }));
      });
  }
};

// EDIT EXPENSE
export const editExpense = (id, updates) => ({
    type: 'EDIT_EXPENSE',
    id,
    updates
});

export const startEditExpense = (id, updates) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;

    const editExpenseRef = ref(database, `users/${uid}/expenses/${id}`);

    return update(editExpenseRef, updates)
      .then(() => {
        dispatch(editExpense(id, updates));
      });
  }
};

// REMOVE EXPENSE
export const removeExpense = ({ id } = {}) => ({
    type: 'REMOVE_EXPENSE',
    id
});

export const startRemoveExpense = ({ id } = {}) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    const removeExpenseRef = ref(database, `users/${uid}/expenses/${id}`);
    
    return remove(removeExpenseRef)
      .then(() => {
        dispatch(removeExpense({ id }));
      });
  }
};

// SET EXPENSES
export const setExpenses = (expenses) => ({
  type: 'SET_EXPENSES',
  expenses
});

export const startSetExpenses = () => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;

    const expensesRef = ref(database, `users/${uid}/expenses`);

    return get(expensesRef, (snapshot) => {
      const expenses = [];

      snapshot.forEach((childSnapshot) => {
        expenses.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        });
      });

      dispatch(setExpenses(expenses));
    })
  }
};