import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import selectExpenses from '../selectors/monthlyExpenses';
import selectIncome from '../selectors/monthlyIncome';
import selectBoth from '../selectors/expenses';
import StatisticsFilters from './StatisticsFilters';
import { Reducer } from "../functions/functions";

const StatisticsReports = (props) => {

  const expensesTotal = Reducer(props.expenses, 'isExpense');
  const expenses = expensesTotal.map(expense => (expense.amount / 100));

  const incomeTotal = Reducer(props.income, 'isExpense');
  const income = incomeTotal.map(income => (income.amount / 100));

  const options = {
    chart: {
      type: 'bar',
    },
    title: {
      text: 'Reports'
    },
    tooltip: {
      formatter: function() {
        return `${this.series.name} <strong>${Math.round(this.point.percentage)}%</strong>`
      }
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false
    },
    plotOptions: {
      bar: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return Math.round(this.y / this.total * 100) + '%';
          }
        }
      }
    },
    series: [{
      name: 'Expenses',
      data: expenses,
      color: '#F2676B'
    }, {
      name: 'Income',
      data: income,
      color: '#00ADB5'
    }]
  };

  return (
    <div>
      <StatisticsFilters />
      <div className="content-container">
        <hr className="hidden" />
        <div className="chart-container">
          {
            props.expenses.length > 0 ? (
              <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
              <p className="chart-container__empty">No expenses this month <span>Remove Date Range Filter to view All</span></p>
            )
          }
        </div>
        <div className="reports-container">
          <div className="reports reports-income">
            <div className="reports__title">
              <span>Income</span>
              <span>{numeral(income).format('$0,0.00')}</span>
            </div>
            <div className="reports__text">
              <span>Count</span>
              <span>{props.income.length}</span>
            </div>
          </div>
          <div className="reports reports-expenses">
            <div className="reports__title">
              <span>Expenses</span>
              <span>{numeral(expenses).format('$0,0.00')}</span>
            </div>
            <div className="reports__text">
              <span>Count</span>
              <span>{props.expenses.length}</span>
            </div>
          </div>
          <div className="reports reports-balance">
            <div className="reports__title">
              <span>Cashflow</span>
              <span>{numeral(income-expenses).format('$0,0.00')}</span>
            </div>
            <div className="reports__text">
              <span>Net Ending Balance</span>
              <span>{numeral(income-expenses).format('$0,0.00')}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    expenses: selectExpenses(state.expenses, state.filters),
    income: selectIncome(state.expenses, state.filters),
    both: selectBoth(state.expenses, state.filters)
  }
};

export default connect(mapStateToProps)(StatisticsReports)