import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { startAddExpense } from '../actions/expenses';
import selectCategories from '../selectors/categories';

class AddPage extends Component {
  onSubmit = (expense) => {
    this.props.startAddExpense(expense);
    // expense.isExpense === true ? this.props.history.push('/') : this.props.history.push('/income');
    this.props.history.push('/');
  };

  render() {
    const ExpenseVar = this.props.match.params.type;
    return (
      <div>
        <div className={ExpenseVar === 'expense' ? 'page-header page-header--expense' : 'page-header page-header--income'}>
          <div className="content-container">
            <h1 className="page-header__title">{ExpenseVar === 'expense' ? 'Add Expense' : 'Add Income'}</h1>
          </div>
        </div>
        <div className="content-container">
          <Form
              type={this.props.match.params.type === 'expense'}
              expensesCategories={this.props.expensesCategories}
              onSubmit={this.onSubmit}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    expensesCategories: selectCategories(state.categories, state.filters)
  }
};

const mapDispatchToProps = (dispatch) => ({
  startAddExpense: (expense) => dispatch(startAddExpense(expense))
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPage);