import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ListItem from './ListItem';
import selectIncome from '../selectors/income';

export const List = (props) => {

  const handleAdd = (e) => {
    e.target.classList.toggle('active');
  };

  return (
    <div id="listFade" className="content-container">
      {/*<div className="list-header">*/}
        {/*<div className="show-for-mobile">Income</div>*/}
        {/*<div className="show-for-desktop">Income</div>*/}
        {/*<div className="show-for-desktop">Amount</div>*/}
      {/*</div>*/}
      <div className="list-body">
        {
          props.expenses.length === 0 ? (
              <div className="list-item list-item--message">
                <span>No income</span>
              </div>
          ) : (
              props.expenses.map((expense) => {
                return <ListItem key={expense.id} {...expense} />
              })
          )
        }
      </div>
      <div className="add" onClick={handleAdd}>
        <Link to="/add/expense" className="add--expense">Expense</Link>
        <Link to="/add/income" className="add--income">Income</Link>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    expenses: selectIncome(state.expenses, state.filters)
  }
};

export default connect(mapStateToProps)(List);