import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import selectExpenses from '../selectors/monthlyExpenses';
import selectIncome from '../selectors/monthlyIncome';
import selectBoth from '../selectors/expenses';
import StatisticsFilters from './StatisticsFilters';
import { ReducerMonths } from "../functions/functions";

const StatisticsBoth = (props) => {

  const expensesTotal = ReducerMonths(props.expenses);
  const expenses = expensesTotal.map(expense => (expense.amount / 100));

  const incomeTotal = ReducerMonths(props.income);
  const income = incomeTotal.map(income => (income.amount / 100));

  const labels = ReducerMonths(props.both);
  const categories = labels.map(single => moment(single.createdAt).format('MMMM YYYY'));

  let balance = [];
  for (let i = 0; i < income.length; i++) {
    balance.push(Math.abs(income[i] - expenses[i]));
  }

  const options = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Balance'
    },
    tooltip: {
      valueSuffix: '$'
    },
    xAxis: {
      categories: categories
    },
    yAxis: {
      title: {
        text: false
      },
      labels: {
        formatter: function () {
          return this.value + '$';
        }
      }
    },
    series: [{
      name: 'Balance',
      data: balance,
      color: '#F5CC70'
    }]
  };

  return (
    <div>
      <StatisticsFilters />
      <div className="content-container">
        <hr className="hidden" />
        <div className="chart-container">
          {
            props.expenses.length > 0 ? (
              <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
              <p className="chart-container__empty">No expenses this month <span>Remove Date Range Filter to view All</span></p>
            )
          }
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    expenses: selectExpenses(state.expenses, state.filters),
    income: selectIncome(state.expenses, state.filters),
    both: selectBoth(state.expenses, state.filters)
  }
};

export default connect(mapStateToProps)(StatisticsBoth)