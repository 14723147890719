import React from 'react';
import { connect } from 'react-redux';
import numeral from 'numeral';
import selectExpenses from '../selectors/monthlyExpenses';
import selectExpensesTotal from '../selectors/expenses-total';

const Summary = ({ expenseCount, expensesTotal }) => {
  const expenseWord = expenseCount === 1 ? 'expense' : 'expenses';
  const formattedExpensesTotal = numeral(expensesTotal / 100).format('$0,0.00');

  return (
    <div className="summary">
      <h4 className="summary__title">Viewing</h4>
      <div className="summary__all">
        <h1 className="summary__expenses">{expenseCount}</h1>
        <div className="summary__total">
          <h3>{expenseWord}</h3>
          <h4>totalling <span className="summary__expense">{formattedExpensesTotal}</span></h4>
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  const visibleExpenses = selectExpenses(state.expenses, state.filters);

  return {
    expenseCount: visibleExpenses.length,
    expensesTotal: selectExpensesTotal(visibleExpenses)
  };
};

export default connect(mapStateToProps)(Summary);