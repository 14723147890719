import moment from 'moment';

export const capitalize = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const Reducer = (array, type) => {
  const arr = array.reduce((accumulator, currentEl) => {
    const exists = accumulator.find(el => el[type] === currentEl[type]);
    const i = accumulator.findIndex(el => el[type] === currentEl[type]);

    if (exists) {
      accumulator[i] = {
        ...exists,
        amount: exists.amount + currentEl.amount
      }
    } else {
      accumulator.push(currentEl);
    }
    return accumulator;
  }, []);

  return arr;
};

export const ReducerMonths = (array) => {
  const arr = array.reduce((accumulator, currentEl) => {
    const exists = accumulator.find(el => moment(el.createdAt).isSame(currentEl.createdAt, 'month'));
    const i = accumulator.findIndex(el => moment(el.createdAt).isSame(currentEl.createdAt, 'month'));

    if (exists) {
      accumulator[i] = {
        ...exists,
        amount: exists.amount + currentEl.amount
      }
    } else {
      accumulator.push(currentEl);
    }
    return accumulator;
  }, []);

  return arr;
};