import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { setTextFilter, setCategoryFilter, sortByDate, sortByAmount, setStartDate, setEndDate } from '../actions/filters';
import selectCategories from '../selectors/categories';
import {capitalize} from "../functions/functions";
import { FilterList } from '@material-ui/icons';

class ListFilters extends Component {
  state = {
    calendarFocused: null,
    showFilters: false
  };

  handleFilters = () => {
    const list = document.getElementById('listFade');
    list.classList.toggle('active');
    this.setState((prevState) => ({ showFilters: !prevState.showFilters }))
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.props.dispatch(setStartDate(startDate));
    this.props.dispatch(setEndDate(endDate));
  };

  onFocusChange = (calendarFocused) => {
    this.setState(() => ({ calendarFocused }));
  };

  render() {
    return (
      <div className={`content-container filters-space ${this.state.showFilters ? 'fadeIn-filters' : 'fadeOut-filters'}`}>
        <div className={`input-group ${this.state.showFilters ? 'show-filters' : 'hide-filters'}`}>
          <div className="input-group__item">
            <select
              className="select"
              value={this.props.filters.sortBy}
              onChange={(e) => {
                if (e.target.value === 'date') {
                  this.props.dispatch(sortByDate());
                } else if (e.target.value === 'amount') {
                  this.props.dispatch(sortByAmount());
                }
              }}>
              <option value="date">Filter by Date</option>
              <option value="amount">Filter by Amount</option>
            </select>
          </div>
          <div className="input-group__item input-group__item--categories">
            <select
              className="select"
              value={this.props.filters.category}
              onChange={(e) => {
                this.props.dispatch(setCategoryFilter(e.target.value));
              }}>
              <option value="">All categories</option>
              {
                this.props.expensesCategories &&
                this.props.expensesCategories.map((category) => {
                  if (category.type === 'expenses') {
                    return (<option value={category.name} key={category.id}>{capitalize(category.name)}</option>);
                  }
                })
              }
            </select>
          </div>
        </div>
        <div className="input-group">
          <div className="input-group__item input-group__item--datepicker">
            <DateRangePicker
                startDate={this.props.filters.startDate}
                startDateId="startDateId"
                endDate={this.props.filters.endDate}
                endDateId="startDateId"
                onDatesChange={this.onDatesChange}
                focusedInput={this.state.calendarFocused}
                onFocusChange={this.onFocusChange}
                showClearDates={true}
                numberOfMonths={1}
                isOutsideRange={() => false}
            />
          </div>
          <div className="input-group__two">
            <div className="input-group__item input-group__item--search">
              <input
                  type="text"
                  className="text-input"
                  placeholder="Search expenses"
                  value={this.props.filters.text}
                  onChange={(e) => {
                    this.props.dispatch(setTextFilter(e.target.value));
                  }}
              />
            </div>
            <button type="button" className="filters-button" onClick={this.handleFilters}><FilterList /></button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
    expensesCategories: selectCategories(state.categories, state.filters)
  }
};

export default connect(mapStateToProps)(ListFilters);