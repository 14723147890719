import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CategoryForm from './CategoryForm';
import { startEditCategory, startRemoveCategory } from '../actions/categories';

class EditCategory extends Component {
  onSubmit = (category) => {
    this.props.startEditCategory(this.props.category.id, category);
    this.props.history.push('/categories');
  };
  onRemove = () => {
    this.props.startRemoveCategory({ id: this.props.category.id });
    this.props.history.push('/categories');
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <div className="content-container">
            <h1 className="page-header__title">Edit Category</h1>
          </div>
        </div>
        <div className="content-container">
          <CategoryForm
            item={this.props.category}
            onSubmit={this.onSubmit}
          />
          <button className="button button--secondary" onClick={this.onRemove}>Remove Category</button>
        </div>
        <Link to="/add-category" className="add add--category"></Link>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    category: state.categories.find((category) => category.id === props.match.params.id)
  }
};

const mapDispatchToProps = (dispatch, props) => ({
  startEditCategory: (id, category) => dispatch(startEditCategory(id, category)),
  startRemoveCategory: (data) => dispatch(startRemoveCategory(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);