// TEXT FILTER
export const setTextFilter = (text = '') => ({
    type: 'SET_TEXT_FILTER',
    text
});

export const setCategoryFilter = (category = '') => ({
    type: 'SET_CATEGORY_FILTER',
    category
});

// SORT BY DATE
export const sortByDate = () => ({
    type: 'SORT_BY_DATE'
});

// SORT BY AMOUNT
export const sortByAmount = () => ({
    type: 'SORT_BY_AMOUNT'
});

// START DATE
export const setStartDate = (startDate) => ({
    type: 'SET_START_DATE',
    startDate
});

// END DATE
export const setEndDate = (endDate) => ({
    type: 'SET_END_DATE',
    endDate
});

// SORT CATEGORY BY TYPE
export const sortByType = (categoryType = '') => ({
    type: 'SORT_BY_TYPE',
    categoryType
});