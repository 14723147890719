import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { startLogout } from "../actions/auth";
import { Menu } from '@material-ui/icons';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navigationOpened: false
    }
  }

  handleNavigation = () => {
    this.setState((prevState) => ({
      navigationOpened: !prevState.navigationOpened
    }))
  };

  render () {
    return (
      <header className="header">
        <div className="content-container">
          <div className="header__content">
            <Link className="header__title" to="/dashboard">
              <h1>Budget App</h1>
            </Link>
            <div className="header__user">
              <img src={this.props.user.avatar !== null ? this.props.user.avatar : '/images/avatar.png'} width="50" height="50" alt="avatar"/>
              <h4>{this.props.user.name}</h4>
              <Menu className="header__icon header__icon--menu" onClick={this.handleNavigation} />
              <nav className={`header__navigation ${this.state.navigationOpened ? 'header__navigation--opened' : 'header__navigation--closed'}`}>
                <Link to="/dashboard">Home</Link>
                <Link to="/income">Income</Link>
                {/*<Link to="/add/expense">Add Expense</Link>*/}
                {/*<Link to="/add/income">Add Income</Link>*/}
                <Link to="/categories">Categories</Link>
                {/*<Link to="/add-category">Add Category</Link>*/}
                <Link to="/statistics">Statistics</Link>
                <Link to="/account">Account</Link>
                <a onClick={this.props.startLogout}>Logout</a>
              </nav>
            </div>
          </div>
        </div>
      </header>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth
});

const mapDispatchToProps = (dispatch) => ({
  startLogout: () => dispatch(startLogout())
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);