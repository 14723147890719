import React from 'react';
import { connect } from 'react-redux';
import { startLoginGoogle, startLoginFacebook, startLoginEmail } from "../actions/auth";
import LoginModal from './LoginModal';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: null,
      modalState: false
    }
  }

  handleModal = () => {
    this.setState((prevState) => ({
      modalState: !prevState.modalState
    }))
  };

  handleEmail = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email }))
  };

  handlePassword = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password }))
  };

  onSubmit = (e) => {
    e.preventDefault();
    const {
      email,
      password
    } = this.state;

    this.props.startLoginEmail(email, password)
      .then(() => {
        this.setState(() => ({
          email: '',
          password: '',
          error: null
        }))
      })
      .catch((error) => {
        // const errorCode = error.code;
        const errorMessage = error.message;
        this.setState(() => ({
          error: errorMessage
        }))
      })
  };

  render () {

    return (
      <div className="box-layout">
        <div className="box-layout__box">
          <h1 className="box-layout__title">Login</h1>
          <p>Manage your budget with style</p>
          <button className="button button--google" onClick={this.props.startLoginGoogle}>Login with Google</button>
          <button className="button button--facebook" onClick={this.props.startLoginFacebook}>Login with Facebook</button>
          <button className="button button--email" onClick={this.handleModal}>Login with Email/Password</button>
          <LoginModal
            modalState={this.state.modalState}
            handleModal={this.handleModal}
            handleEmail={this.handleEmail}
            handlePassword={this.handlePassword}
            email={this.state.email}
            password={this.state.password}
            error={this.state.error}
            onSubmit={this.onSubmit}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  startLoginGoogle: () => dispatch(startLoginGoogle()),
  startLoginFacebook: () => dispatch(startLoginFacebook()),
  startLoginEmail: (email, password) => dispatch(startLoginEmail(email, password))
});

export default connect(undefined, mapDispatchToProps)(LoginPage);