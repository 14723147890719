import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { doPasswordReset } from "../actions/auth";
import { ArrowBack } from '@material-ui/icons';

class ForgotPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: null
    }
  }

  handleEmail = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email }))
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.doPasswordReset(this.state.email)
      .then(() => {
        this.setState(() => ({ email: '' }));
        alert('Password Reset Email Sent!');
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/invalid-email') {
          this.setState(() => ({ error: errorMessage }));
        } else if (errorCode === 'auth/user-not-found') {
          this.setState(() => ({ error: errorMessage }));
        }
      })
  };
  render () {
    const isInvalid = this.state.email === '';
    return (
      <div className="forgot-layout">
        <div className="forgot-layout__box">
          <Link to="/" className="back"><ArrowBack /></Link>
          <h1 className="forgot-layout__title">Forgot Password</h1>
          {this.state.error && <p className="error">{this.state.error}</p>}
          <div className="form-wrapper">
            <form className="form" onSubmit={this.onSubmit}>
              <input
                type="email"
                className="text-input"
                value={this.state.email}
                onChange={this.handleEmail}
                placeholder="Your Email Address"
              />
              <button disabled={isInvalid} type="submit" className="button button--forgot">Send Email</button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  doPasswordReset: (email) => dispatch(doPasswordReset(email))
});

export default connect(undefined, mapDispatchToProps)(ForgotPage);