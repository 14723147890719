import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { AccountBalanceWallet, List, Equalizer, AccountCircle } from '@material-ui/icons';

const MobileMenu = () => {
  const handleAdd = (e) => {
    e.target.classList.toggle('active');
  };

  return (
      <div className="footer-wrapper">
        <div className="footer-menu">
          <nav className="footer-menu__nav">
            <div className="footer-menu__side">
              <NavLink to="/dashboard" activeClassName="active"><AccountBalanceWallet/><span>Expenses</span></NavLink>
              <NavLink to="/categories" activeClassName="active"><List/><span>Categories</span></NavLink>
            </div>
            <div className="footer-menu__side">
              <NavLink to="/statistics" activeClassName="active"><Equalizer/><span>Statistics</span></NavLink>
              <NavLink to="/account" activeClassName="active"><AccountCircle/><span>Account</span></NavLink>
            </div>
          </nav>
        </div>
        <div className="add" onClick={handleAdd}>
          <Link to="/add/expense" className="add--expense">Expense</Link>
          <Link to="/add/income" className="add--income">Income</Link>
        </div>
      </div>
  );
};

export default MobileMenu;