import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import { TrendingUp, TrendingDown } from '@material-ui/icons';

const ListItem = ({ id, description, category, amount, createdAt, isExpense }) => {
  const type = isExpense ? 'expense' : 'income';
  return (
    <Link className="list-item" to={`/edit/${id}`}>
      <div className="list-item__content">
        <div className={`list-item__icon ${type}`}>{isExpense ? <TrendingDown /> : <TrendingUp />}</div>
        <div className="list-item__text">
          <h3 className="list-item__title">{description}</h3>
          <span className="list-item__subtitle">{moment(createdAt).format('MMMM Do, YYYY')}</span>
        </div>
      </div>
      <h3 className="list-item__data"><span className={type}>{isExpense ? '-' : '+'} {numeral(amount / 100).format('$0,0.00')}</span></h3>
    </Link>
  );
};

export default ListItem;