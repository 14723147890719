import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CategoryForm from './CategoryForm';
import { startAddCategory } from '../actions/categories';

class AddCategory extends Component {
  onSubmit = (category) => {
    this.props.startAddCategory(category);
    this.props.history.push('/categories');
  };

  render() {
    return (
      <div>
        <div className="page-header">
          <div className="content-container">
            <h1 className="page-header__title">Add Category</h1>
          </div>
        </div>
        <div className="content-container">
          <CategoryForm
            onSubmit={this.onSubmit}
          />
        </div>
        <Link to="/add-category" className="add add--category"></Link>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  startAddCategory: (category) => dispatch(startAddCategory(category))
});

export default connect(undefined, mapDispatchToProps)(AddCategory);