import React from 'react';
import { Link } from 'react-router-dom';

const Statistics = () => {

  return (
    <div className="content-container">
      <h1 className="statistics__title">Statistics</h1>
      <div className="statistics">
        <Link to="/statistics-reports" className="statistics__single">
          <svg id='statistics-reports' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <rect x='32' y='7' width='10' height='45' fill='#14a085' />
            <rect x='17' y='38' width='10' height='14' fill='#f29c1f' />
            <rect x='2' y='29' width='10' height='23' fill='#e57e25' />
            <rect x='47' y='15' width='10' height='37' fill='#71c285' />
            <path d='M59,53H1c-0.552,0-1-0.447-1-1s0.448-1,1-1h58c0.552,0,1,0.447,1,1S59.552,53,59,53z'
                  fill='#556080' />
          </svg>
          <h3>Reports</h3>
        </Link>
        <Link to="/statistics-both" className="statistics__single">
          <svg id='statistics-both' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 293.2 300'>
            <rect x='7' y='7' width='179.9' height='223.9' className='st0' />
            <rect className='st1' width='179.9' height='223.9' />
            <rect x='24.7' y='55.6' className='st2' width='14.1' height='29' />
            <rect x='50.3' y='26.6' className='st2' width='14.1' height='57.9' />
            <rect x='75.8' y='40.3' className='st2' width='14.1' height='44.2' />
            <rect x='19.8' y='104.9' className='st3' width='140.4' height='9.2' />
            <rect x='19.8' y='125.5' className='st3' width='140.4' height='9.2' />
            <rect x='19.8' y='146.1' className='st3' width='140.4' height='9.2' />
            <rect x='19.8' y='166.6' className='st3' width='140.4' height='9.2' />
            <rect x='19.8' y='187.2' className='st3' width='72.1' height='9.2' />
            <g>
              <rect x='113.3' y='76.1' width='179.9' height='223.9' className='st0'
              />
              <rect x='106.3' y='69.1' className='st4' width='179.9' height='223.9'
              />
            </g>
            <g>
              <rect x='131' y='124.7' className='st5' width='14.1' height='29' />
              <rect x='156.5' y='95.8' className='st5' width='14.1' height='57.9' />
              <rect x='182.1' y='109.5' className='st5' width='14.1' height='44.2' />
            </g>
            <g>
              <rect x='126' y='174.1' className='st3' width='140.4' height='9.2' />
              <rect x='126' y='194.7' className='st3' width='140.4' height='9.2' />
              <rect x='126' y='215.2' className='st3' width='140.4' height='9.2' />
              <rect x='126' y='235.8' className='st3' width='140.4' height='9.2' />
              <rect x='126' y='256.3' className='st3' width='72.1' height='9.2' />
            </g>
          </svg>
          <h3>Income &amp; Expenses</h3>
        </Link>
        <Link to="/statistics-categories" className="statistics__single">
          <svg id='statistics-categories' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 58 58'>
            <path d='M45,29c0,8.837-7.163,16-16,16v13c16.016,0,29-12.984,29-29S45.016,0,29,0v13 C37.837,13,45,20.163,45,29z'
                  fill='#f0c419' />
            <path d='M29,58c8.008,0,15.258-3.246,20.506-8.494c-4.542-4.542-5.29-5.29-9.192-9.192 C37.418,43.209,33.418,45,29,45s-8.418-1.791-11.314-4.686l-9.192,9.192C13.742,54.754,20.992,58,29,58z'
                  fill='#e57e25' />
            <path d='M13,29c0-8.837,7.163-16,16-16V0C12.984,0,0,12.984,0,29c0,8.008,3.246,15.258,8.494,20.506 l9.192-9.192C14.791,37.418,13,33.418,13,29z'
                  fill='#26b99a' />
          </svg>
          <h3>Categories</h3>
        </Link>
        <Link to="/statistics-balance" className="statistics__single">
          <svg id='statistics-balance' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'>
            <g fill='#9777a8'>
              <path d='M18.306,33.28l-5.013,5.013c-0.391,0.391-0.391,1.023,0,1.414C13.488,39.902,13.744,40,14,40 s0.512-0.098,0.707-0.293l5.013-5.013c0.391-0.391,0.391-1.023,0-1.414S18.696,32.89,18.306,33.28z'
              />
              <path d='M27.694,33.28c-0.391-0.391-1.023-0.391-1.414,0s-0.391,1.023,0,1.414l6.025,6.025 c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L27.694,33.28z'
              />
              <path d='M50.707,30.293c-0.391-0.391-1.023-0.391-1.414,0l-10,10c-0.391,0.391-0.391,1.023,0,1.414 C39.488,41.902,39.744,42,40,42s0.512-0.098,0.707-0.293l10-10C51.098,31.316,51.098,30.684,50.707,30.293z'
              />
            </g>
            <path d='M3,60c-0.553,0-1-0.447-1-1V1c0-0.553,0.447-1,1-1s1,0.447,1,1v58C4,59.553,3.553,60,3,60z'
                  fill='#556080' />
            <path d='M59,58H1c-0.553,0-1-0.447-1-1s0.447-1,1-1h58c0.553,0,1,0.447,1,1S59.553,58,59,58z'
                  fill='#556080' />
            <path d='M9,29H7c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S9.553,29,9,29z'
                  fill='#bdc3c7' />
            <path d='M9,25H7c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S9.553,25,9,25z'
                  fill='#bdc3c7' />
            <path d='M9,21H7c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S9.553,21,9,21z'
                  fill='#bdc3c7' />
            <path d='M9,17H7c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S9.553,17,9,17z'
                  fill='#bdc3c7' />
            <path d='M9,13H7c-0.553,0-1-0.447-1-1s0.447-1,1-1h2c0.553,0,1,0.447,1,1S9.553,13,9,13z'
                  fill='#bdc3c7' />
            <path d='M9,9H7C6.447,9,6,8.553,6,8s0.447-1,1-1h2c0.553,0,1,0.447,1,1S9.553,9,9,9z'
                  fill='#bdc3c7' />
            <path d='M9,5H7C6.447,5,6,4.553,6,4s0.447-1,1-1h2c0.553,0,1,0.447,1,1S9.553,5,9,5z'
                  fill='#bdc3c7' />
            <circle cx='11.012' cy='42.988' r='5' fill='#f29c1f' />
            <circle cx='23' cy='31' r='5' fill='#71c285' />
            <circle cx='54' cy='28' r='5' fill='#71c285' />
            <circle cx='36' cy='44' r='5' fill='#f29c1f' />
          </svg>
          <h3>Balance</h3>
        </Link>
      </div>
    </div>
  );
};

export default Statistics;