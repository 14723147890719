export default [
  {
    name: 'salary',
    type: 'income',
    color: '#00AD76'
  },
  {
    name: 'deposits',
    type: 'income',
    color: '#72C674'
  },
  {
    name: 'savings',
    type: 'income',
    color: '#FFD034'
  },
  {
    name: 'bills',
    type: 'expenses',
    color: '#F34605'
  },
  {
    name: 'car',
    type: 'expenses',
    color: '#009BCE'
  },
  {
    name: 'clothes',
    type: 'expenses',
    color: '#F594BD'
  },
  {
    name: 'food',
    type: 'expenses',
    color: '#FF7C5B'
  },
  {
    name: 'health',
    type: 'expenses',
    color: '#11C5D9'
  },
  {
    name: 'house',
    type: 'expenses',
    color: '#0092C7'
  },
  {
    name: 'transport',
    type: 'expenses',
    color: '#7984A0'
  },
  {
    name: 'entertainment',
    type: 'expenses',
    color: '#F2385A'
  }
]