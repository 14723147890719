import moment from 'moment';

// FILTERS REDUCER
const filtersReducerDefaultState = {
    text: '',
    category: '',
    categoryType: '',
    sortBy: 'date',
    startDate: moment().startOf('month'),
    endDate: moment().endOf('month')
};

export default (state = filtersReducerDefaultState, action) => {
    switch (action.type) {
        case 'SET_TEXT_FILTER':
            return {
                ...state,
                text: action.text
            };
        case 'SET_CATEGORY_FILTER':
            return {
                ...state,
                category: action.category
            };
        case 'SORT_BY_AMOUNT':
            return {
                ...state,
                sortBy: 'amount'
            };
        case 'SORT_BY_DATE':
            return {
                ...state,
                sortBy: 'date'
            };
        case 'SET_START_DATE':
            return {
                ...state,
                startDate: action.startDate
            };
        case 'SET_END_DATE':
            return {
                ...state,
                endDate: action.endDate
            };
        case 'SORT_BY_TYPE':
            return {
                ...state,
                categoryType: action.categoryType
            };
        default:
            return state;
    }
};
