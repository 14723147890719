import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from './Form';
import { startEditExpense, startRemoveExpense } from '../actions/expenses';
import selectCategories from '../selectors/categories';

class EditPage extends Component {
  onSubmit = (expense) => {
    this.props.startEditExpense(this.props.expense.id, expense);
    expense.isExpense === true ? this.props.history.push('/') : this.props.history.push('/income')
  };
  onRemove = () => {
    this.props.startRemoveExpense({ id: this.props.expense.id });
    this.props.expense.isExpense === true ? this.props.history.push('/') : this.props.history.push('/income')
  };

  render() {
    const ExpenseVar = this.props.expense.isExpense;
    return (
      <div>
        <div className={ExpenseVar ? 'page-header page-header--expense' : 'page-header page-header--income'}>
          <div className="content-container">
            <h1 className="page-header__title">{ExpenseVar ? 'Edit Expense' : 'Edit Income'}</h1>
          </div>
        </div>
        <div className="content-container">
          <Form
            expensesCategories={this.props.expensesCategories}
            item={this.props.expense}
            onSubmit={this.onSubmit}
          />
          <button className="button button--secondary" onClick={this.onRemove}>Remove {ExpenseVar ? 'Expense' : 'Income'}</button>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {
    expense: state.expenses.find((expense) => expense.id === props.match.params.id),
    expensesCategories: selectCategories(state.categories, state.filters)
  }
};

const mapDispatchToProps = (dispatch, props) => ({
  startEditExpense: (id, expense) => dispatch(startEditExpense(id, expense)),
  startRemoveExpense: (data) => dispatch(startRemoveExpense(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditPage);