import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setTextFilter, sortByType } from '../actions/filters';

class CategoriesFilters extends Component {
  render() {
    return (
      <div className="content-container mt-3">
        <div className="input-group">
          <div className="input-group__item">
            <input
              type="text"
              className="text-input"
              placeholder="Search categories"
              value={this.props.filters.text}
              onChange={(e) => {
                this.props.dispatch(setTextFilter(e.target.value));
              }}
            />
          </div>
          <div className="input-group__item">
            <select
              className="select"
              value={this.props.filters.sortByType}
              onChange={(e) => {
                this.props.dispatch(sortByType(e.target.value));
              }}>
              <option value="">All types</option>
              <option value="income">Income</option>
              <option value="expenses">Expenses</option>
            </select>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters
  }
};

export default connect(mapStateToProps)(CategoriesFilters);