import React from 'react';
import { connect } from 'react-redux';
import { capitalize, Reducer } from '../functions/functions';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import selectExpenses from '../selectors/expenses';
import StatisticsFilters from './StatisticsFilters';

const StatisticsCategories = (props) => {

  const expenses = Reducer(props.expenses, 'category');

  const expensesData = expenses.reduce((accumulator, currentEl) => {
    if (!accumulator[currentEl.category]) {
      const category = props.expensesCategories.find(el => el.name === currentEl.category);

      accumulator.push({
          ...currentEl,
          color: category.color
      })
    }
    return accumulator;
  }, []);

  const data = expensesData.map(expense => {
    expense.y = expense['amount'] / 100;
    expense.name = capitalize(expense['category']);
    return expense;
  });

  const options = {
    chart: {
      type: 'pie',
    },
    tooltip: {
      pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false
        },
        showInLegend: true
      }
    },
    title: {
      text: 'Categories'
    },
    series: [{
      name: 'Categories',
      data: data
    }]
  };

  return (
    <div>
      <StatisticsFilters />
      <div className="content-container">
        <hr className="hidden" />
        <div className="chart-container">
          {
            props.expenses.length > 0 ? (
              <HighchartsReact highcharts={Highcharts} options={options} />
            ) : (
              <p className="chart-container__empty">No expenses this month <span>Remove Date Range Filter to view All</span></p>
            )
          }
        </div>
      </div>
    </div>
  )
};

const mapStateToProps = (state) => {
  return {
    expenses: selectExpenses(state.expenses, state.filters),
    expensesCategories: state.categories
  }
};

export default connect(mapStateToProps)(StatisticsCategories)