import React from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Clear } from '@material-ui/icons';

const LoginModal = (props) => (
  <Modal
    isOpen={props.modalState}
    onRequestClose={props.handleModal}
    contentLabel="Login Modal"
    closeTimeoutMS={200}
    className="modal"
  >
    <button className="close" onClick={props.handleModal}><Clear /></button>
    <h1 className="modal__title">Login</h1>
    <div className="form-wrapper">
      {props.error && <p className="error">{props.error}</p>}
      <form className="form" onSubmit={props.onSubmit}>
        <label htmlFor="email">Email</label>
        <input
            type="email"
            id="email"
            className="text-input"
            autoFocus
            value={props.email}
            onChange={props.handleEmail}
        />
        <label htmlFor="password">Password</label>
        <input
            type="password"
            id="password"
            className="text-input"
            value={props.password}
            onChange={props.handlePassword}
        />
        <button className="button button--modal">Login</button>
      </form>
      <Link to="/forgot">Forgot Password?</Link>
      <p>Don't have an account? <Link to="/register">Sign Up</Link></p>
    </div>
  </Modal>
);

export default LoginModal;