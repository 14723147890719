import React from 'react';
import Categories from './Categories';
import CategoriesFilters from './CategoriesFilters';

const CategoriesDashboardPage = () => (
  <div>
    <CategoriesFilters />
    <Categories />
  </div>
);

export default CategoriesDashboardPage;