import moment from 'moment';

// GET VISIBLE INCOME ON MONTH
export default (expenses, { startDate, endDate }) => {
  return expenses.filter((expense) => {
    const onlyIncome = !expense.isExpense;
    const createdAtMoment = moment(expense.createdAt);
    const startDateMatch = startDate ? startDate.isSameOrBefore(createdAtMoment, 'day') : true;
    const endDateMatch = endDate ? endDate.isSameOrAfter(createdAtMoment, 'day') : true;

    return onlyIncome && startDateMatch && endDateMatch;
  }).sort((a, b) => {
    return a.createdAt < b.createdAt ? 1 : -1;
  });
}