import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { setStartDate, setEndDate } from '../actions/filters';
import selectCategories from '../selectors/categories';
import { FilterList, ArrowBack } from '@material-ui/icons';

class StatisticsFilters extends Component {
  state = {
    calendarFocused: null,
    showFilters: false
  };

  handleFilters = () => {
    this.setState((prevState) => ({ showFilters: !prevState.showFilters }))
  };

  onDatesChange = ({ startDate, endDate }) => {
    this.props.dispatch(setStartDate(startDate));
    this.props.dispatch(setEndDate(endDate));
  };

  onFocusChange = (calendarFocused) => {
    this.setState(() => ({ calendarFocused }));
  };

  render() {
    return (
      <div className="content-container filters-space">
        <div className="input-group input-group--statistics">
          {/*<div className="input-group__item input-group__item--categories">*/}
            {/*<select*/}
              {/*className="select"*/}
              {/*value={this.props.filters.category}*/}
              {/*onChange={(e) => {*/}
                {/*this.props.dispatch(setCategoryFilter(e.target.value));*/}
              {/*}}>*/}
              {/*<option value="">All categories</option>*/}
              {/*{*/}
                {/*this.props.expensesCategories &&*/}
                {/*this.props.expensesCategories.map((category) => {*/}
                  {/*return (<option value={category.name} key={category.id}>{capitalize(category.name)}</option>);*/}
                {/*})*/}
                {/*}*/}
            {/*</select>*/}
          {/*</div>*/}
          <div className="back-wrapper">
            <Link to="/statistics" className="back back--normal"><ArrowBack /></Link>
            <button type="button" className="filters-button" onClick={this.handleFilters}><FilterList /></button>
          </div>
          <div className={`input-group__item input-group__item--datepicker statisticsDate ${this.state.showFilters ? 'show-filters' : 'hide-filters'}`}>
            <DateRangePicker
              startDate={this.props.filters.startDate}
              startDateId="startDateId"
              endDate={this.props.filters.endDate}
              endDateId="startDateId"
              onDatesChange={this.onDatesChange}
              focusedInput={this.state.calendarFocused}
              onFocusChange={this.onFocusChange}
              showClearDates={true}
              numberOfMonths={1}
              isOutsideRange={() => false}
            />
          </div>
        </div>
        <div className="input-group">
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    filters: state.filters,
    expensesCategories: selectCategories(state.categories, state.filters)
  }
};

export default connect(mapStateToProps)(StatisticsFilters);