import uuid from 'uuid';
import defaultCategories from '../fixtures/defaultCategories';
import { database } from '../firebase/firebase';
import { get, onValue, push, ref, remove } from 'firebase/database';

// ADD CATEGORY
export const addCategory = (category) => ({
    type: 'ADD_CATEGORY',
    category
});

export const startAddCategory = (categoryData = {}) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    const {
      name = '',
      type = '',
      color = ''
    } = categoryData;
    const category = {name, type, color};

    const addCategoryRef = ref(database, `users/${uid}/categories`);

    return push(addCategoryRef, category)
      .then((ref) => {
        dispatch(addCategory({
          id: ref.key,
          ...category
        }))
      })
  }
};

export const startAddDefaultCategories = () => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;

    const addDefaultRef = ref(database, `users/${uid}`);

    return get(addDefaultRef, (snapshot) => {
      if (!snapshot.hasChild('categories')) {
        defaultCategories.forEach((category) => {
          return startAddCategory(category);
        })
      }
    })
  }
};

// EDIT CATEGORY
export const editCategory = (id, updates) => ({
    type: 'EDIT_CATEGORY',
    id,
    updates
});

export const startEditCategory = (id, updates) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;
    const editCategoryRef = ref(database, `users/${uid}/categories/${id}`);

    return update(editCategoryRef, updates)
      .then(() => {
        dispatch(editCategory(id, updates));
      });
  }
};

// REMOVE CATEGORY
export const removeCategory = ({ id } = {}) => ({
    type: 'REMOVE_CATEGORY',
    id
});

export const startRemoveCategory = ({ id } = {}) => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;

    const removeCategoryRef = ref(database, `users/${uid}/categories/${id}`);

    return remove(removeCategoryRef)
      .then(() => {
        dispatch(removeCategory({ id }));
      })
  }
};

// SET CATEGORIES
export const setCategories = (categories) => ({
  type: 'SET_CATEGORIES',
  categories
});

export const startSetCategories = () => {
  return (dispatch, getState) => {
    const uid = getState().auth.uid;

    const categoriesRef = ref(database, `users/${uid}/categories`);

    return get(categoriesRef, (snapshot) => {
      const categories = [];

      snapshot.forEach((childSnapshot) => {
        categories.push({
          id: childSnapshot.key,
          ...childSnapshot.val()
        });
      });

      console.log('here');
      console.log(categories);

      dispatch(setCategories(categories));
    })
  }
};
