import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { registerEmailPassword, emailVerification } from '../actions/auth';
import { ArrowBack } from '@material-ui/icons';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: '',
      email: '',
      passwordOne: '',
      passwordTwo: '',
      error: null
    }
  }

  handleUsername = (e) => {
    const username = e.target.value;
    this.setState(() => ({ username }))
  };

  handleEmail = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email }))
  };

  handlePasswordOne = (e) => {
    const passwordOne = e.target.value;
    this.setState(() => ({ passwordOne }))
  };

  handlePasswordTwo = (e) => {
    const passwordTwo = e.target.value;
    this.setState(() => ({ passwordTwo }))
  };

  onSubmit = (e) => {
    e.preventDefault();

    const {
      username,
      email,
      passwordOne
    } = this.state;

    this.props.registerEmailPassword(username, email, passwordOne)
      .then(() => {
        this.setState(() => ({
          username: '',
          email: '',
          passwordOne: '',
          passwordTwo: '',
          error: ''
        }));
        this.props.emailVerification()
          .then(() => {
            alert('Email verification sent!');
          })
          .catch((error) => {
            // const errorCode = error.code;
            const errorMessage = error.message;
            this.setState(() => ({ error: errorMessage }));
          })
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/weak-password') {
          alert (errorMessage);
        } else {
          this.setState(() => ({ error: errorMessage }));
        }
      })
  };

  render () {
    const isInvalid =
        this.state.passwordOne !== this.state.passwordTwo ||
        this.state.passwordOne === '' ||
        this.state.email === '' ||
        this.state.username === '';
    return (
      <div className="register-box">
        <div className="register-box__box">
          <Link to="/" className="back"><ArrowBack /></Link>
          <h1 className="register-box__title">Sign Up</h1>
          { this.state.error && <p className="error">{this.state.error}</p> }
          <form className="form" onSubmit={this.onSubmit}>
            <input
              type="text"
              className="text-input"
              value={this.state.username}
              onChange={this.handleUsername}
              autoFocus
              placeholder="Full Name"
            />
            <input
              type="text"
              className="text-input"
              value={this.state.email}
              onChange={this.handleEmail}
              placeholder="Email Address"
            />
            <input
              type="password"
              className="text-input"
              value={this.state.passwordOne}
              onChange={this.handlePasswordOne}
              placeholder="Password"
            />
            <input
              type="password"
              className="text-input"
              value={this.state.passwordTwo}
              onChange={this.handlePasswordTwo}
              placeholder="Confirm Password"
            />
            <p className="form__p">*All fields are required.</p>
            <button disabled={isInvalid} type="submit" className="button button--register">Sign Up</button>
          </form>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => ({
  registerEmailPassword: (username, email, password) => dispatch(registerEmailPassword(username, email, password)),
  emailVerification: () => dispatch(emailVerification())
});

export default connect(undefined, mapDispatchToProps)(RegisterPage);